<template>
    <div class="course-ware-content">
        <div class="resource-classify">
            <div class="head-title">个人资源</div>
            <div class="back-btn">
                <el-button type="primary" size="medium" @click="uploadFile">上传资源</el-button>
                <el-button type="primary" size="medium" @click="goBack">返 回</el-button>
            </div>
        </div>
        <el-scrollbar class="course-ware-list">
            <div class="course-ware">
                <table class="dataTabble" rules=rows>
                    <thead class="table-head">
                    <tr class="table-head-title">
                        <th class="th-title1">标题</th>
                        <th class="th-title2">文件类型</th>
                        <th class="th-title3">上传时间</th>
                        <th class="th-title4">操作</th>
                    </tr>
                    </thead>
                    <tbody class="table-body">
                    <tr v-for="(item,index) in resourceData" :key="index" class="table-trBox">
                        <td class="td-title">{{item.title}}</td>
                        <td>{{item.file_ext}}</td>
                        <td>{{item.create_time}}</td>
                        <td class="opt-box">
                            <div class="opt-innerBox">
                                <el-button type="text" @click="downloadFile(item)">下载</el-button>
                                <el-button type="text" @click="viewDetail(item)">查看详情</el-button>
                                <el-button type="text" style="color: #F50000" @click="delAppBtn(item)">删除
                                </el-button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <!--                    </draggable>-->
                </table>
                <div v-if="resourceData.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
            <div class="page-box">
                <el-pagination
                        v-if="resourceData.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-scrollbar>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
        <el-dialog title="上传资料" :visible.sync="dialogUploadMaterial" width="650px"
                   class="carousel-dialog upload-resources-dialog" @close="resetUploadMaterialForm()"
                   :close-on-click-modal="false" append-to-body>
            <el-form :model="uploadMaterialForm" ref="uploadMaterialForm" :rules="uploadMaterialRules"
                     label-position="top" class="info-form">
                <el-form-item label="资料标题：" label-width="100px" prop="title">
                    <el-input v-model="uploadMaterialForm.title" autocomplete="off" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="选择文件：" label-width="100px" prop="file" class="file">
                    <div class="file-name">
                        <div class="upload-cover">
                            <!--                            <i class="iconfont">&#xe659;</i>-->
                            <span>{{uploadMaterialForm.file ? '重新上传' : '上传'}}</span>
                        </div>
                        <input ref="uploadFile" type="file" accept="*," @change="changeMaterial($event)"
                               class="cover-input">
                        <div class="up-img-show" v-show="uploadMaterialForm.fileName">
                            <span class="file-name">{{uploadMaterialForm.fileName}}</span>
                            <el-progress :percentage="percent"></el-progress>
                            <i class="iconfont del-icon" @click="delMaterial">&#xe651;</i>
                        </div>
                    </div>
                    <span class="text" style="color: #F56C6C;" v-if="isUploadPDF">*请上传同文件pdf格式,以便浏览使用</span>
                    <div class="file-name" v-if="isPDFInput">
                        <div class="upload-cover">
                            <!--                            <i class="iconfont">&#xe659;</i>-->
                            <span>{{uploadMaterialForm.pdfFile ? '重新上传' : '上传PDF'}}</span>
                        </div>
                        <input type="file" ref="uploadMaterial" accept="*," @change="changeMaterialPDF($event)"
                               class="cover-input">
                        <div class="up-img-show" v-show="uploadMaterialForm.pdfName">
                            <span class="file-name">{{uploadMaterialForm.pdfName}}</span>
                            <el-progress :percentage="pdfPercent"></el-progress>
                            <i class="iconfont del-icon" @click="delPdf">&#xe651;</i>
                        </div>
                    </div>
                    <span class="text">(文件支持格式为mp4、avi、pdf、word、excel、ppt、jpg、png、zip和rar)</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="resetUploadMaterialForm">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveUploadMaterial('uploadMaterialForm')"
                           :disabled="isUploadPDF">确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ResourceDialog from "../../../components/teacher/courseCenter/ResourceDialog";
    import {
        personalAdd,
        mergeSlice,
        sliceUpload,
        personalList,
        personalDel
    } from '@/utils/apis';
    import Upload from '@/utils/slice_upload';

    export default {
        name: "studentMatingDetail",
        props: ['nodeIndex', 'chapterIndex'],
        components: {
            ResourceDialog
        },
        data() {
            return {
                currentPage: 1,
                pageSize: 6,
                total: "",
                //是否显示上传资料弹窗
                dialogUploadMaterial: false,
                //上传资料数据表单
                uploadMaterialForm: {
                    select_resources_type: '',
                    title: '',
                    resource_file_type: '',
                    file: '',
                    fileName: '',
                    file_path: '',
                    pdfFile: '',
                    pdfName: '',
                    file_preview_path: '',
                },
                uploadMaterialRules: {
                    select_resources_type: [
                        {required: true, message: '请选择资源类型', trigger: 'change'}
                    ],
                    file: [
                        {required: true, message: '请上传文件', trigger: 'change'}
                    ],
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                        {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
                    ],
                },
                //是否要上传PDF 文件
                isUploadPDF: false,
                isPDFInput: false,
                typeList: [],
                classId: null,
                resourceData: [],
                adminBtnShow: true,
                checkAll: false,
                isIndeterminate: true,
                selectAppList: [],
                applicationIdList: [],
                src: "",
                dialogVisible: false,
                saveTemplateShow: false,
                templateName: '',
                tempPoverShow: false,
                resourceList: [],
                courseContent: [],
                select_course_id: this.$route.query.course_id,
                papersList: [],
                projectsList: [],
                material_list: [],
                cKejian: [],
                cJiaocai: [],
                cWeike: [],
                cDonghua: [],
                cPeitao: [],
                cJiaoan: [],
                cZhishidian: [],
                cSucaiku: [],
                cAnliku: [],
                cPeiWeike: [],
                cXiangguanwenzhang: [],
                cPersonResource: [],
                testCenter: [],
                practiceCenter: [],
                showPeitao: [],
                course_content: [],
                selectTemplateList: [],
                courseTempList: [],
                uploadInstance: null,
                uploadPdfInstance: null,
                percent: 0,
                pdfPercent: 0,
            }
        },
        mounted() {
            this.getMaterialList();
        },
        methods: {
            getMaterialList() {
                let param = {
                    course_id: this.select_course_id,
                    chapter_index: this.chapterIndex,
                    section_index: this.nodeIndex,
                    page: this.currentPage,
                    limit: 6
                }
                personalList(param).then(res => {
                    this.$message.success(res.msg);
                    this.total = res.data.total;
                    this.resourceData = res.data.list;
                })
            },
            uploadFile() {
                this.dialogUploadMaterial = true;
            },
            goBack() {
                this.$router.go(-1)
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
            //重置上传资料表单
            resetUploadMaterialForm() {
                this.dialogUploadMaterial = false;
                this.uploadMaterialForm = {
                    select_resources_type: '',
                    title: '',
                    resource_file_type: '',
                    file: '',
                    fileName: '',
                    file_path: '',
                    pdfFile: '',
                    pdfName: '',
                    file_preview_path: '',
                }
                this.isPDFInput = false;
            },
            delMaterial() {
                this.uploadMaterialForm.file = '';
                this.uploadMaterialForm.fileName = '';
                this.uploadMaterialForm.file_path = '';
            },
            delPdf() {
                this.uploadMaterialForm.pdfFile = '';
                this.uploadMaterialForm.pdfName = '';
                this.uploadMaterialForm.file_preview_path = '';
            },
            //上传资料
            changeMaterial(event) {
                let fileList = event.target.files[0];
                this.uploadMaterialForm.file = fileList;
                this.uploadMaterialForm.fileName = fileList.name;
                let chunkSize = 1024 * 1024 * 8;
                this.uploadInstance = new Upload(fileList, chunkSize, sliceUpload);
                let index = 0;
                this.uploadSlice(index);
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                            this.uploadMaterialForm.file_path = res.data.src;
                            let fileType = res.data.src.split('.')[1]
                            if (fileType === 'doc') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'docx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'xls') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'xlsx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'ppt') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'pptx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else {
                                this.isUploadPDF = false;
                                this.isPDFInput = false;
                            }
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                            this.uploadMaterialForm.file_path = res.data.src;
                            let fileType = res.data.src.split('.')[1]
                            if (fileType === 'doc') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'docx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'xls') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'xlsx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'ppt') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else if (fileType === 'pptx') {
                                this.isUploadPDF = true;
                                this.isPDFInput = true;
                                return;
                            } else {
                                this.isUploadPDF = false;
                                this.isPDFInput = false;
                            }
                        }
                    }
                });
            },
            changeMaterialPDF(event) {
                let fileList = event.target.files[0];
                this.uploadMaterialForm.pdfFile = fileList;
                this.uploadMaterialForm.pdfName = fileList.name;
                let chunkSize = 1024 * 1024 * 8;
                this.uploadPdfInstance = new Upload(fileList, chunkSize, sliceUpload);
                let index = 0;
                this.uploadSlicePdf(index);
            },
            uploadSlicePdf(index) {
                let num = this.uploadPdfInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadPdfInstance.file_tmp,
                        slice_size: this.uploadPdfInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.pdfPercent = 100;
                            this.$refs.uploadMaterial.value = null
                            let fileType = res.data.src.split('.')[1]
                            if (fileType === 'pdf') {
                                this.isUploadPDF = false;
                                this.uploadMaterialForm.file_preview_path = res.data.src;
                            } else {
                                this.$message.error('请上传同文件pdf格式');
                            }
                        }
                    });
                    return;
                }
                this.uploadPdfInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.pdfPercent = Math.ceil((index / num) * 100);
                            this.uploadSlicePdf(++index);
                        } else {
                            this.pdfPercent = 100;
                            this.$refs.uploadMaterial.value = null
                            let fileType = res.data.src.split('.')[1]
                            if (fileType === 'pdf') {
                                this.isUploadPDF = false;
                                this.uploadMaterialForm.file_preview_path = res.data.src;
                            } else {
                                this.$message.error('请上传同文件pdf格式');
                            }
                        }
                    }
                });
            },
            saveUploadMaterial() {
                let formData = new FormData();
                formData.append('title', this.uploadMaterialForm.title);
                formData.append('path', this.uploadMaterialForm.file_path);
                formData.append('preview_path', this.uploadMaterialForm.file_path);
                formData.append('course_id', this.select_course_id);
                formData.append('chapter_index', this.chapterIndex);
                formData.append('section_index', this.nodeIndex);
                if (this.uploadMaterialForm.file_preview_path) {
                    formData.append('preview_path', this.uploadMaterialForm.file_preview_path);
                }
                personalAdd(formData).then(res => {
                    this.$message.success(res.msg)
                    this.resetUploadMaterialForm();
                    this.getMaterialList();
                }).catch(err => {
                    console.error(err)
                })
            },
            handleSizeChange(val) {
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getMaterialList();
            },
            downloadFile(item) {
                window.open(localStorage.getItem('hosturl') + 'personal/download/' + item.id);
            },
            viewDetail(item) {
                let fileType = item.file_ext.toLowerCase()
                if (fileType === 'rar' || fileType === 'zip') {
                    window.open(item.preview_path, '_blank')
                } else {
                    this.dialogVisible = true;
                    this.src = item.preview_path;
                }
            },
            delAppBtn(item) {
                this.$confirm('删除后不能恢复，确定是否删除？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    personalDel(item.id).then(res => {
                        this.$message.success(res.msg);
                        this.getMaterialList();
                    }).catch(err => {
                        console.error(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .upload-resources-dialog {

        ::v-deep .el-dialog__header {
            background: #2DC079;

            .el-dialog__title {
                color: #ffffff;
            }

            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }

        .info-form {
            .file {
                ::v-deep .el-form-item__content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                }

                .file-name {
                    display: flex;
                    position: relative;
                }

                .upload-cover {
                    width: 100px;
                    height: 40px;
                    background: #2DC079;
                    border-radius: 4px;
                    color: #ffffff;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i.iconfont {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }

                .cover-input {
                    position: absolute;
                    top: 0;
                    width: 100px;
                    height: 30px;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .resources-class-cascader {
                width: 100%;
            }

            .select-course {
                width: 100%;
            }
        }

        .up-img-show {
            display: flex;
            align-items: center;

            .file-name {
                display: inline-block;
                width: 1%;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-progress {
                margin-left: 20px;
                width: 160px;
            }

            .del-icon {
                cursor: pointer;
            }
        }
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #fff;


        .resource-classify {
            display: flex;
            align-items: center;
            padding: 26px 30px;
            position: relative;

            .head-title {
                font-size: 18px;
            }

            .back-btn {
                position: absolute;
                right: 30px;
            }

            span {
                display: inline-block;
                line-height: 1;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                font-weight: 400;
                cursor: pointer;
            }

            .class-item {
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                color: #2DC079;
            }

            &:after {
                content: '';
                position: absolute;
                left: 36px;
                right: 33px;
                bottom: 0;
                height: 1px;
                background: #e9e9e9;
            }
        }

        .administration {
            /*display: flex;*/
            .administration-box {
                padding: 0 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .admin-btn {
                    padding: 8px 28px;
                }
            }
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            ::v-deep .el-scrollbar__view {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .course-ware {
                padding: 0px 30px;
                display: flex;
                flex-wrap: wrap;

                .dataTabble {
                    width: 100%;
                    margin-top: 18px;
                    border-bottom: 1px solid #DDDDDD;

                    .table-head {
                        width: 100%;
                        height: 50px;
                        background: #F6F6F6;

                        .table-head-title {
                            height: 50px;
                            font-size: 16px;

                            th {
                                color: #333333;
                                font-weight: 400;
                                width: 25%;
                            }

                            .th-title1 {
                                text-align: left;
                                padding-left: 40px;
                            }
                        }
                    }

                    .table-body {
                        height: 100%;
                        border-bottom: 1px solid #DDDDDD;

                        .table-trBox {
                            height: 100px;
                            position: relative;

                            td {
                                text-align: center;
                            }

                            .td-title {
                                text-align: left;
                                padding-left: 40px;
                            }

                            .opt-box {
                                height: 100px;

                                a {
                                    display: flex;
                                    align-items: center;
                                    width: 33%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-box {
            margin-top: 20px;
            text-align: center;
            margin-bottom: 20px;
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            position: relative;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                padding: 6px;
                display: flex;
                position: relative;

                .new-module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 15px;
                }

                .module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 24px;
                    margin-right: 13px;
                }

                .item-cover {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }
                }

                .item-cover1 {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }

                    .show-img {
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .img-box {
                            width: 50px;
                            height: 50px;
                            border-radius: 24px;
                        }
                    }
                }

                .show1 {
                    background: #61BCEB;
                }

                .show2 {
                    background: #3DCAC8;
                }

                .show3 {
                    background: #F0BC49;
                }

                .show4 {
                    background: #EC75A4;
                }

                .show5 {
                    background: #79ACDC;
                }

                .show6 {
                    background: #61BCEB;
                }

                .show7 {
                    background: #5AB9E9;
                }

                .courseware {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .textbook {
                    background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                }

                .lesson-plan {
                    background: linear-gradient(0deg, #53DED1, #2BB9BF);
                }

                .micro-lesson {
                    background: linear-gradient(0deg, #EE89B5, #EA5D90);
                }

                .animation {
                    background: linear-gradient(0deg, #F39E72, #EC6C6C);
                }

                .test-center {
                    background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                }

                .training-center {
                    background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                }

                .knowledge-points {
                    background: linear-gradient(0deg, #77C9F1, #47AEE4);
                }

                .material-library {
                    background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                }

                .case-library {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .related-copywriting {
                    background: linear-gradient(0deg, #8BC3EB, #628EC9);
                }

                .personal-info {
                    background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                }

                .del-btn {
                    width: 18px;
                    height: 18px;
                    background: #FF0000;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }

                .show-title {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                }

                .new-module-item:before {
                    content: "";
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                }


                .new-module-item:hover:before {
                    width: 100%;
                    left: 0;
                    opacity: 0.5;
                }

                .new-module-item:hover .del-btn {
                    opacity: 1;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #2DC079;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                //line-height: 60px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                font-weight: 400;
                margin-right: 20px;

                &:hover {
                    cursor: pointer;
                }
            }

            .adhibition-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                width: 60px;
                height: 60px;
                background: #2DC079;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

</style>